import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash-es';
import { CustomType, FlagCode, TimeZoneGMT } from 'app/core/shared/shared.type';
import { applicationStatusData, issueStatusData } from 'app/core/shared/reference-data/_application-status.data';
import { flagCodesData } from 'app/core/shared/reference-data/_flag-codes.data';
import { serviceOfferData } from 'app/core/shared/reference-data/_service-offer.data';
import { locationTypeData } from 'app/core/shared/reference-data/_location-type.data';
import { timezoneData } from 'app/core/shared/reference-data/_timezone.data';
import { regionData } from 'app/core/shared/reference-data/_region.data';
import { facilityTypeData } from 'app/core/shared/reference-data/_facility-type.data';
import { platformTypeData } from 'app/core/shared/reference-data/_platform-type.data';
import { subsiteTypeData } from 'app/core/shared/reference-data/_subsite-type.data';
import { currencyData } from 'app/core/shared/reference-data/_currency.data';
import { unitsData, unitTypeData, unitConversionByTypeData } from 'app/core/shared/reference-data/_unit-dimension.data';
import { wellOriginalTypeData } from 'app/core/shared/reference-data/_well-original-type.data';
import { wellStructureTypeData } from 'app/core/shared/reference-data/_well-structure-type.data';
import { dataModelData } from 'app/core/shared/reference-data/_data-model.data';
import { toolPositionData } from 'app/core/shared/reference-data/_tool-position.data';
import { packageTypeData } from 'app/core/shared/reference-data/_package-type.data';
import { approvalCandidateData } from 'app/core/shared/reference-data/_approval-candidate.data';
import { jobCategoryData } from 'app/core/shared/reference-data/_job-category.data';
import { stringIndicatorData } from 'app/core/shared/reference-data/_string-indicator.data';
import { permissionPageData } from 'app/core/shared/reference-data/_permission-page.data';
import { personnelShiftData } from 'app/core/shared/reference-data/_personnel-shift.data';
import { jobStatusData } from 'app/core/shared/reference-data/_job-status.data';
import { extractionModelData } from 'app/core/shared/reference-data/_extraction-model.data';

@Injectable({ providedIn: 'root' })
export class SharedMockApi {
  private _applicationStatus: CustomType[] = applicationStatusData;
  private _issueStatus: CustomType[] = issueStatusData;
  private _flagCodes: FlagCode[] = flagCodesData;
  private _serviceOffers: CustomType[] = serviceOfferData;
  private _locationTypes: CustomType[] = locationTypeData;
  private _timezoneGMT: TimeZoneGMT[] = timezoneData;
  private _regions: CustomType[] = regionData;
  private _facilityTypes: CustomType[] = facilityTypeData;
  private _platformTypes: CustomType[] = platformTypeData;
  private _subsiteTypes: CustomType[] = subsiteTypeData;
  private _currencies: CustomType[] = currencyData;
  private _units: CustomType[] = unitsData;
  private _unitTypes: CustomType[] = unitTypeData;
  private _unitConversions: CustomType[] = unitConversionByTypeData;
  private _wellOriginalTypes: CustomType[] = wellOriginalTypeData;
  private _wellStructureTypes: CustomType[] = wellStructureTypeData;
  private _dataModels: CustomType[] = dataModelData;
  private _toolPositions: CustomType[] = toolPositionData;
  private _packageTypes: CustomType[] = packageTypeData;
  private _approvalCandidate: CustomType[] = approvalCandidateData;
  private _jobCategory: CustomType[] = jobCategoryData;
  private _stringIndicator: CustomType[] = stringIndicatorData;
  private _permissionPage: CustomType[] = permissionPageData;
  private _personnelShift: CustomType[] = personnelShiftData;
  private _jobStatus: CustomType[] = jobStatusData;
  private _extractionModel: CustomType[] = extractionModelData;

  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    this._fuseMockApiService
      .onGet('api/lookup/data/applicationStatus')
      .reply(() => [200, cloneDeep(this._applicationStatus)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/issueStatus')
      .reply(() => [200, cloneDeep(this._issueStatus)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/flagCode')
      .reply(() => [200, cloneDeep(this._flagCodes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/serviceOffer')
      .reply(() => [200, cloneDeep(this._serviceOffers)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/locationType')
      .reply(() => [200, cloneDeep(this._locationTypes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/timezoneGMT')
      .reply(() => [200, cloneDeep(this._timezoneGMT)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/region')
      .reply(() => [200, cloneDeep(this._regions)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/facilityType')
      .reply(() => [200, cloneDeep(this._facilityTypes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/platformType')
      .reply(() => [200, cloneDeep(this._platformTypes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/subsiteType')
      .reply(() => [200, cloneDeep(this._subsiteTypes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/currency')
      .reply(() => [200, cloneDeep(this._currencies)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/unit')
      .reply(() => [200, cloneDeep(this._units)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/unitType')
      .reply(() => [200, cloneDeep(this._unitTypes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/unitConversionsByType')
      .reply(() => [200, cloneDeep(this._unitConversions)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/wellOriginalType')
      .reply(() => [200, cloneDeep(this._wellOriginalTypes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/wellStructureType')
      .reply(() => [200, cloneDeep(this._wellStructureTypes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/dataModel')
      .reply(() => [200, cloneDeep(this._dataModels)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/toolPosition')
      .reply(() => [200, cloneDeep(this._toolPositions)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/packageType')
      .reply(() => [200, cloneDeep(this._packageTypes)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/approvalCandidate')
      .reply(() => [200, cloneDeep(this._approvalCandidate)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/jobCategory')
      .reply(() => [200, cloneDeep(this._jobCategory)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/stringIndicator')
      .reply(() => [200, cloneDeep(this._stringIndicator)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/permissionPage')
      .reply(() => [200, cloneDeep(this._permissionPage)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/personnelShift')
      .reply(() => [200, cloneDeep(this._personnelShift)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/jobStatus')
      .reply(() => [200, cloneDeep(this._jobStatus)]);

    this._fuseMockApiService
      .onGet('api/lookup/data/extractionModel')
      .reply(() => [200, cloneDeep(this._extractionModel)]);
  }
}
