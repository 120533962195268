import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { UnitDimensionForm } from 'app/core/shared/types/metadata/unit-dimension.type';

@Injectable({ providedIn: 'root' })
export class MetadataUnitDimensionsMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/configurations/metadata/unit-dimension')
      .reply(() => {
        // Generate a new unit dimension
        const newUnitDimension: UnitDimensionForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: null,
          [Field.Type]: '',
          [Field.OriginalUnit]: null,
          [Field.TargetUnit]: null,
          [Field.ConversionFactor]: 0,
          [Field.ReverseConversionFactor]: 0
        };

        // Return the response
        return [200, newUnitDimension];
      });
  }
}
