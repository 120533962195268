<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions" class="ml-3" [ngClass]="{'bg-gray-50 bg-opacity-20': currentRoute}">
  <span class="relative">
    <img class="w-8 h-8 rounded-full ring-4" *ngIf="showAvatar && user.avatar" [src]="user.avatar" alt="Avatar">
    <div class="flex items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-white ring-4 text-primary text-lg"
      *ngIf="!showAvatar || !user.avatar">
      {{user.name.charAt(0)}}{{user.name.split(' ')[1].charAt(0)}}
    </div>
    <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <div class="mx-4 mb-4 mt-2">
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-primary font-medium">{{user.name}}</span>
    </span>
  </div>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item [ngClass]="{'bg-gray-200': currentRoute && currentRoute.title === 'Profile Settings'}" (click)="openProfileSettings()">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button>
  <!--
  <button mat-menu-item [matMenuTriggerFor]="userStatus">
    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
    <span>Status</span>
  </button>
-->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu>