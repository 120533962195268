import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { UserSigninForm, UserSigninResp, UserSignupForm, UserSignupResp } from 'app/core/shared/types/user.type';
import { Field } from 'app/core/custom/field.enum';
import { ResponseResult } from 'app/core/shared/shared.type';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: UserSignupForm): Observable<ResponseResult<UserSignupResp>> {
    return this._httpClient.post<ResponseResult<UserSignupResp>>(`${environment.apiURL}/Account/RegisterAccount`, user);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: UserSigninForm): Observable<ResponseResult<UserSigninResp>> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => 'User is already logged in.');
    }

    return this._httpClient.post<ResponseResult<UserSigninResp>>(`${environment.apiURL}/${environment.apiVersion}/Accounts/Authenticate`, credentials).pipe(
      switchMap((response: ResponseResult<UserSigninResp>) => {
        // Store the access token in the local storage
        this.accessToken = response.data.token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = AuthUtils.tokenizedInfo(this.accessToken);

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Forgot password
   *
   * @param resetForm
   */
  forgotPassword(resetForm: Pick<UserSigninForm, Field.Email>): Observable<ResponseResult<string>> {
    return this._httpClient.post<ResponseResult<string>>(`${environment.apiURL}/Account/ForgotPassword`, resetForm);
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<boolean> {
    // Sign in using the token
    return this._httpClient.post('api/auth/sign-in-with-token', {
      accessToken: this.accessToken,
    }).pipe(
      catchError(() =>

        // Return false
        of(false),
      ),
      switchMap((response: any) => {
        // Replace the access token with the new one if it's available on
        // the response object.
        //
        // This is an added optional step for better security. Once you sign
        // in using the token, you should generate a new one on the server
        // side and attach it to the response object. Then the following
        // piece of code can replace the token with the refreshed one.
        if (response.accessToken) {
          this.accessToken = response.accessToken;
        }

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return true
        return of(true);
      }),
    );
  }

  /**
   * Continue sign in from existing token
   */
  continueSignIn(): Observable<boolean> {
    // Get the access token from the local storage
    this.accessToken = localStorage.getItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = true;
    
    // Store the user on the user service
    this._userService.user = AuthUtils.tokenizedInfo(this.accessToken);

    // Return the observable
    return of(true);
  }

  /**
   * Sign out
   */
  signOut(): Observable<boolean> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Refresh token
   *
   * @param credentials
   */
  refreshToken(): Observable<any> {
    // Get the access token from the local storage
    this.accessToken = localStorage.getItem('accessToken');

    // Extract the token
    const tokenizedInfo = AuthUtils.tokenizedInfo(this.accessToken);

    // Set the params
    const params = {
      userId: tokenizedInfo.id,
      token: tokenizedInfo.refreshToken
    }

    return this._httpClient.post<ResponseResult<UserSigninResp>>(`${environment.apiURL}/${environment.apiVersion}/Accounts/RefreshToken`, params).pipe(
      switchMap((response: ResponseResult<UserSigninResp>) => {
        // Update the new access token in the local storage
        this.accessToken = response.data.token;

        // Store the user on the user service
        this._userService.user = AuthUtils.tokenizedInfo(this.accessToken);

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists, and it didn't expire, continue sign in using it
    return this.continueSignIn();
  }
}
