/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { UserRoleEnum } from 'app/core/user/user.enum';

export const subNavigation: FuseNavigationItem[] = [
  {
    id: 'superdashboards',
    type: 'group',
    children: [
      {
        id: 'superdashboards.analytics',
        title: 'Analytics',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/dashboards/analytics'
      }
    ]
  },
  {
    id: 'assistance',
    type: 'group',
    children: [
      {
        id: 'assistance.account-management',
        title: 'Account Management',
        type: 'collapsable',
        icon: 'heroicons_outline:identification',
        accessRole: [UserRoleEnum.ST],
        children: [
          {
            id: 'assistance.account-management.manage-user',
            title: 'Manage User',
            type: 'basic',
            icon: 'heroicons_outline:user',
            link: '/assistance/account-management/manage-user',
            badge: {
              title: 'In Progress!',
              classes: 'px-2 bg-teal-400 text-black rounded'
            }
          }
        ]
      },
      {
        id: 'assistance.incident-ticket',
        title: 'Incident Ticket',
        type: 'basic',
        icon: 'heroicons_outline:ticket',
        link: '/assistance/incident-ticket',
        disabled: true,
        badge: {
          title: 'Coming Soon!',
          classes: 'px-2 bg-yellow-300 text-black rounded'
        }
      },
      {
        id: 'assistance.faq',
        title: 'FAQ',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: '/assistance/faq',
        disabled: true,
        badge: {
          title: 'Coming Soon!',
          classes: 'px-2 bg-yellow-300 text-black rounded'
        }
      },
      {
        id: 'assistance.maintenance',
        title: 'Maintenance',
        type: 'collapsable',
        icon: 'heroicons_outline:wrench-screwdriver',
        children: [
          {
            id: 'assistance.maintenance.server-health',
            title: 'Server Health',
            type: 'basic',
            icon: 'heroicons_outline:server-stack',
            link: '/assistance/maintenance/server-health',
            disabled: true,
            badge: {
              title: 'Coming Soon!',
              classes: 'px-2 bg-yellow-300 text-black rounded'
            }
          },
          {
            id: 'assistance.maintenance.log-audit',
            title: 'Log & Audit Trail',
            type: 'basic',
            icon: 'heroicons_outline:wrench',
            link: '/assistance/maintenance/log-audit',
            disabled: true,
            badge: {
              title: 'Coming Soon!',
              classes: 'px-2 bg-yellow-300 text-black rounded'
            }
          },
          {
            id: 'assistance.maintenance.storage-capacity',
            title: 'Storage Capacity',
            type: 'basic',
            icon: 'heroicons_outline:circle-stack',
            link: '/assistance/maintenance/storage-capacity',
            disabled: true,
            badge: {
              title: 'Coming Soon!',
              classes: 'px-2 bg-yellow-300 text-black rounded'
            }
          }
        ]
      },
      {
        id: 'assistance.support',
        title: 'Support',
        type: 'collapsable',
        icon: 'heroicons_outline:megaphone',
        accessRole: [UserRoleEnum.ST, UserRoleEnum.CS],
        children: [
          {
            id: 'assistance.support.tc',
            title: 'T&C',
            type: 'basic',
            icon: 'heroicons_outline:check-badge',
            link: '/assistance/support/tc',
            disabled: true,
            badge: {
              title: 'Coming Soon!',
              classes: 'px-2 bg-yellow-300 text-black rounded'
            }
          },
          {
            id: 'assistance.support.social-media',
            title: 'Social Media',
            type: 'basic',
            icon: 'heroicons_outline:share',
            link: '/assistance/support/social-media',
            disabled: true,
            badge: {
              title: 'Coming Soon!',
              classes: 'px-2 bg-yellow-300 text-black rounded'
            }
          },
          {
            id: 'assistance.support.customer-survey',
            title: 'Customer Survey',
            type: 'basic',
            icon: 'heroicons_outline:pencil-square',
            link: '/assistance/support/customer-survey',
            disabled: true,
            badge: {
              title: 'Coming Soon!',
              classes: 'px-2 bg-yellow-300 text-black rounded'
            }
          }
        ]
      }
    ]
  },
  {
    id: 'userdashboards',
    type: 'group',
    children: [
      {
        id: 'userdashboards.analytics',
        title: 'Analytics',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/dashboards/analytics'
      }
    ]
  },
  {
    id: 'userdashboards',
    type: 'group',
    children: [
      {
        id: 'userdashboards.analytics',
        title: 'Analytics',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/dashboards/analytics'
      }
    ]
  },
  {
    id: 'configurations',
    type: 'group',
    children: [
      {
        id: 'configurations.metadata',
        title: 'Metadata Registry',
        type: 'basic',
        icon: 'custom:metadata',
        link: '/configurations/metadata',
        disabled: true,
        badge: {
          title: 'Coming Soon!',
          classes: 'px-2 bg-yellow-300 text-black rounded'
        }
      }
    ]
  },
  {
    id: 'analytics',
    type: 'group',
    children: [
      {
        id: 'analytics.recommendation-system',
        title: 'Recommendation System',
        type: 'basic',
        icon: 'heroicons_outline:cursor-arrow-rays',
        link: '/reportanalytics/recommend-system',
        disabled: true,
        badge: {
          title: 'Coming Soon!',
          classes: 'px-2 bg-yellow-300 text-black rounded'
        }
      },
      {
        id: 'analytics.machine-learning',
        title: 'Machine Learning',
        type: 'basic',
        icon: 'heroicons_outline:light-bulb',
        link: '/reportanalytics/machine-learning',
        disabled: true,
        badge: {
          title: 'Coming Soon!',
          classes: 'px-2 bg-yellow-300 text-black rounded'
        }
      },
      {
        id: 'analytics.entry-simulation',
        title: 'Entry Simulation',
        type: 'basic',
        icon: 'heroicons_outline:arrows-pointing-in',
        link: '/reportanalytics/entry-simulation',
        disabled: true,
        badge: {
          title: 'Coming Soon!',
          classes: 'px-2 bg-yellow-300 text-black rounded'
        }
      },
      {
        id: 'analytics.cost-days-simulation',
        title: 'Cost & Days Simulation',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/reportanalytics/costdays-simulation',
        disabled: true,
        badge: {
          title: 'Coming Soon!',
          classes: 'px-2 bg-yellow-300 text-black rounded'
        }
      },
      {
        id: 'analytics.eda-playground',
        title: 'EDA Playground',
        type: 'basic',
        icon: 'heroicons_outline:code-bracket-square',
        link: '/reportanalytics/eda-playground',
        disabled: true,
        badge: {
          title: 'Coming Soon!',
          classes: 'px-2 bg-yellow-300 text-black rounded'
        }
      }
    ]
  },
  {
    id: 'projects',
    type: 'group',
    children: [
      {
        id: 'projects.file',
        title: 'File Extraction',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-list',
        link: '/projects/extraction',
        badge: {
          title: 'In Progress!',
          classes: 'px-2 bg-teal-400 text-black rounded'
        }
      }
    ]
  },
  {
    id: 'space-route',
    type: 'group',
    children: [
      {
        id: 'space-route.settings.profile',
        title: 'Profile Settings',
        type: 'basic',
        link: '/settings/profile'
      },
      {
        id: 'space-route.settings.company',
        title: 'Company Settings',
        type: 'basic',
        link: '/settings/company'
      }
    ]
  },
  {
    id: 'unknown',
    type: 'group',
    children: [
      {
        id: 'unknown.404',
        title: '404',
        type: 'basic',
        icon: 'heroicons_outline:face-frown',
        link: '/dashboards/panel'
      }
    ]
  }
];
export const superNavigation: FuseNavigationItem[] = [
  {
    id: 'superdashboards',
    title: 'Dashboards',
    type: 'group',
    icon: 'heroicons_outline:rectangle-group',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'assistance',
    title: 'Assistance',
    type: 'group',
    icon: 'heroicons_outline:user-group',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'space-route',
    title: 'Space Route',
    type: 'spacer',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  }
];
export const userNavigation: FuseNavigationItem[] = [
  {
    id: 'userdashboards',
    title: 'Dashboard',
    type: 'group',
    icon: 'heroicons_outline:rectangle-group',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'configurations',
    title: 'Configuration',
    type: 'group',
    icon: 'heroicons_outline:cog',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'analytics',
    title: 'Analytics',
    type: 'group',
    icon: 'heroicons_outline:presentation-chart-bar',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'projects',
    title: 'Project',
    type: 'group',
    icon: 'custom:bus-pro',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'space-route',
    title: 'Space Route',
    type: 'spacer',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  }
];
export const unknownNavigation: FuseNavigationItem[] = [
  {
    id: 'unknown',
    title: 'ERROR',
    type: 'group',
    icon: 'heroicons_outline:no-symbol',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  }
];
