import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { ProjectForm } from 'app/core/shared/types/wso/project.type';
import { PlanAppendixForm } from 'app/core/shared/types/wso/plan/plan-appendix.type';
import { ActualDailyReportForm, ActualPobForm, ActualSupportForm } from 'app/core/shared/types/wso/project-actual.type';

@Injectable({ providedIn: 'root' })
export class WsoProjectMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/business-process/wso/project')
      .reply(() => {
        // Generate a new project
        const newProject: ProjectForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: null,
          [Field.SiteId]: null,
          [Field.SubsiteId]: null,
          [Field.ProjectName]: '',
          [Field.ServiceTypePackages]: [],
          [Field.Wellbores]: [],
          [Field.Wellstrings]: []
        };

        // Return the response
        return [200, newProject];
      });

    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/business-process/wso/plan/appendix')
      .reply(() => {
        // Generate a new appendix
        const newAppendix: PlanAppendixForm = {
          [Field.ID]: 'new',
          [Field.PlanId]: null,
          [Field.Name]: '',
          [Field.File]: null,
          [Field.SortIndex]: 0
        };

        // Return the response
        return [200, newAppendix];
      });

    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/business-process/wso/actual/daily-report')
      .reply(() => {
        // Generate a new actual daily report
        const newActualDailyReport: ActualDailyReportForm = {
          [Field.ID]: 'new',
          [Field.ProjectId]: null,
          [Field.WellStringId]: null,
          [Field.ServiceTypeIds]: [],
          [Field.ReportName]: '',
          [Field.ReportDate]: null,
        };

        // Return the response
        return [200, newActualDailyReport];
      });

    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/business-process/wso/actual/pob')
      .reply(() => {
        // Generate a new actual pob
        const newActualPobReport: ActualPobForm = {
          [Field.ID]: 'new',
          [Field.ProjectId]: null,
          [Field.ServiceTypeId]: null,
          [Field.LinkOperatorOrContractorId]: null,
          [Field.Name]: '',
          [Field.DateOnBoard]: null,
          [Field.DateReturnToBase]: null,
          [Field.Shift]: null,
          [Field.Position]: null,
          [Field.Remark]: null
        };

        // Return the response
        return [200, newActualPobReport];
      });

    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/business-process/wso/actual/support')
      .reply(() => {
        // Generate a new actual support
        const newActualSupport: ActualSupportForm = {
          [Field.ID]: 'new',
          [Field.ProjectId]: null,
          [Field.VesselName]: null,
          [Field.Type]: null,
          [Field.Remark]: null
        };

        // Return the response
        return [200, newActualSupport];
      });
  }
}
