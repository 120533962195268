import { UserLayoutEnum, UserTypeEnum, UserRoleEnum, UserPermissionEnum } from "app/core/user/user.enum";
import { User } from "app/core/shared/types/user.type";

/* eslint-disable */
export const user: User = {
    id    : 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
    name  : 'Brian Hughes',
    email : 'hughes.brian@company.com',
    avatar: 'assets/images/avatars/default.png',
    status: 'online',
    layout: UserLayoutEnum.PUBLIC,
    userType: UserTypeEnum.EXTERNAL,
    roles: [UserRoleEnum.DEFAULT],
    permissions: [UserPermissionEnum.DELETE, UserPermissionEnum.READ],
    refreshToken: ''
};
