import { CustomType } from "app/core/shared/shared.type";

export const regionData: CustomType[] = [
  {
    id: '1',
    label: 'Peninsular Malaysia',
    value: 'Peninsular Malaysia'
  },
  {
    id: '2',
    label: 'Sabah',
    value: 'Sabah'
  },
  {
    id: '3',
    label: 'Sarawak',
    value: 'Sarawak'
  }
]
