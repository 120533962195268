import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { PceForm } from 'app/core/shared/types/metadata/pce.type';

@Injectable({ providedIn: 'root' })
export class MetadataPceMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/configurations/metadata/pce')
      .reply(() => {
        // Generate a new pce
        const newPce: PceForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: null,
          [Field.ImageFile]: null,
          [Field.ToolId]: null,
          [Field.ImageUrl]: null,
          [Field.ImageHeight]: null as number,
          [Field.Offset]: null as number,
          [Field.Name]: '',
          [Field.ShortName]: null,
          [Field.ToolPosition]: null,
          [Field.Description]: null,
          [Field.Remark]: null,
          [Field.ServiceTypeIds]: []
        };

        // Return the response
        return [200, newPce];
      });
  }
}
