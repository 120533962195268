import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { UserRoleForm } from 'app/core/shared/types/roles.type';

@Injectable({ providedIn: 'root' })
export class UserRolePermissionMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/configurations/users/roles-permissions')
      .reply(() => {
        // Generate a new project
        const newRole: UserRoleForm = {
          [Field.CompanyId]: null,
          [Field.RoleId]: 'new',
          [Field.RoleName]: '',
          [Field.PageDetails]: []
        };

        // Return the response
        return [200, newRole];
      });
  }
}
