import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { WellTypeForm } from 'app/core/shared/types/metadata/well-type.type';

@Injectable({ providedIn: 'root' })
export class MetadataWellTypesMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/configurations/metadata/well-type')
      .reply(() => {
        // Generate a new well type
        const newWellType: WellTypeForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: null,
          [Field.TypeName]: '',
          [Field.Abbreviation]: null,
          [Field.Remark]: null
        };

        // Return the response
        return [200, newWellType];
      });
  }
}
