import { CustomType } from "app/core/shared/shared.type";

export const platformTypeData: CustomType[] = [
  {
    id: '1',
    label: 'Platform',
    value: 1
  },
  {
    id: '2',
    label: 'Drilling Platform',
    value: 2
  },
  {
    id: '3',
    label: 'Jacket Platform',
    value: 3
  },
  {
    id: '4',
    label: 'Semi-submersible Platform',
    value: 4
  },
  {
    id: '5',
    label: 'FPSO',
    value: 5
  },
  {
    id: '6',
    label: 'MOPU',
    value: 6
  },
  {
    id: '7',
    label: 'TLP',
    value: 7
  },
  {
    id: '8',
    label: 'Production Barge',
    value: 8
  },
  {
    id: '9',
    label: 'Drillship',
    value: 9
  }
]
