import { CustomType } from "app/core/shared/shared.type";

export const toolPositionData: CustomType[] = [
  {
    id: 'tpm',
    label: 'Top Most',
    value: 'Top Most'
  },
  {
    id: 'btm',
    label: 'Bottom Most',
    value: 'Bottom Most'
  },
  {
    id: 'mid',
    label: 'Middle',
    value: 'Middle'
  },
  {
    id: 'any',
    label: 'Anywhere',
    value: 'Anywhere'
  }
]
