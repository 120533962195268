import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from 'environments/environment';
import { Field } from 'app/core/custom/field.enum';
import { ResponseResult } from 'app/core/shared/shared.type';
import { User, UserCurrentResp } from 'app/core/shared/types/user.type';
import { UserPermissionType } from 'app/core/shared/types/permissions.type';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _userPermissions: ReplaySubject<UserPermissionType[]> = new ReplaySubject<UserPermissionType[]>(1);
  private _userWithCompany: ReplaySubject<UserCurrentResp> = new ReplaySubject<UserCurrentResp>(1);
  spaceRouteClone: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<FuseNavigationItem[]>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

   /**
   * Setter & getter for user permissions
   *
   * @param value
   */
   set userPermissions(value: UserPermissionType[]) {
    // Store the value
    this._userPermissions.next(value);
  }

  get userPermissions$(): Observable<UserPermissionType[]> {
    return this._userPermissions.asObservable();
  }

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set userWithCompany(value: UserCurrentResp) {
    // Store the value
    this._userWithCompany.next(value);
  }

  get userWithCompany$(): Observable<UserCurrentResp> {
    return this._userWithCompany.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user with company data
   */
  getCurrentUser(): Observable<ResponseResult<UserCurrentResp>> {
    return this._httpClient.get<ResponseResult<UserCurrentResp>>(`${environment.apiURL}/${environment.apiVersion}/Users/Me`).pipe(
      tap((resp: ResponseResult<UserCurrentResp>) => {
        resp.data.pageIds = []
        this._userWithCompany.next(resp.data);
      }),
    );
  }

  /**
   * Get the current logged in user with company data
   */
  getUserPermission(item: Pick<FuseNavigationItem, Field.ID>): Observable<ResponseResult<UserPermissionType[]>> {
    return this._httpClient.get<ResponseResult<UserPermissionType[]>>(`${environment.apiURL}/${environment.apiVersion}/EmployeeManagements/PagePermission/PageId/${item.id}`).pipe(
      tap((resp: ResponseResult<UserPermissionType[]>) => {
        this._userPermissions.next(resp.data);
      }),
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      }),
    );
  }
}
