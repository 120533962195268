import { CustomType } from "app/core/shared/shared.type";

export const jobCategoryData: CustomType[] = [
  {
    id: '1',
    label: 'Data Acquisition',
    value: 'Data Acquisition'
  },
  {
    id: '2',
    label: 'Production Enhancement',
    value: 'Production Enhancement'
  },
  {
    id: '3',
    label: 'Well Integrity',
    value: 'Well Integrity'
  },
  {
    id: '4',
    label: 'Others',
    value: 'Others'
  }
]
