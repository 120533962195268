import { CustomType } from "app/core/shared/shared.type";

export const packageTypeData: CustomType[] = [
  {
    id: '9824c9ac-3895-48fc-a12a-311520bd615b',
    label: 'Package 1',
    value: 'P1'
  },
  {
    id: 'e3373349-71b4-4d90-96fe-6b4d3bb7648e',
    label: 'Package 2',
    value: 'P2'
  }
]
