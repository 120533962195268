export enum ResponseEnum {
  IsSuccess = 'isSuccess',
  Message = 'message',
  Errors = 'errors',
  Data = 'data'
}

export enum StatusEnum {
  Disabled = 'disabled',
  Hidden = 'hidden'
}

export enum Pagination {
  HasNextPage = 'hasNextPage',
  HasPreviousPage = 'hasPreviousPage',
  Items = 'items',
  PageNo = 'pageNo',
  PageSize = 'pageSize',
  TotalCount = 'totalCount',
  TotalPages = 'totalPages'
}

export enum Field {
  ID = 'id',
  UserName = 'userName',
  Password = 'password',
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  UserType = 'userType',
  Email = 'email',
  Name = 'name',
  Name2 = 'name2',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  DOB = 'dob',
  Avatar = 'avatar',
  LastLogin = 'lastLogin',
  RefreshToken = 'refreshToken',
  EmployeeId = 'employeeId',
  JobTitle = 'jobTitle',
  Department = 'department',
  Status = 'status',
  IsActive = 'isActive',
  IsAgreed = 'isAgreed',
  ValidDurationMonths = 'validDurationMonths',
  Agreements = 'agreement',
  ConsentGiven = 'consentGiven',
  Message = 'message',
  Owner = 'owner',
  OwnerId = 'ownerId',
  AccountType = 'accountType',
  Company = 'company',
  CompanyName = 'companyName',
  CompanyType = 'companyType',
  CompanyEmail = 'companyEmail',
  CompanyShortName = 'companyShortName',
  CompanyTelp = 'companyTel',
  CompanyDetail = 'companyDetail',
  CompanyId = 'companyId',
  CompanySerialNumber = 'companySerialNumber',
  OfficialWebsite = 'officialWebsite',
  LinkOperatorOrContractorPaginated = 'linkOperatorOrContractorPaginated',
  LinkOperatorOrContractors = 'linkOperatorOrContractors',
  LinkOperatorOrContractorId = 'linkOperatorOrContractorId',
  OperatorOrContractor = 'operatorOrContractor',
  OperatorOrContractorId = 'operatorOrContractorId',
  OperatorOrContractorImageUrl = 'operatorOrContractorImageUrl',
  Offices = 'offices',
  Contracts = 'contracts',
  Division = 'division',
  ContractName = 'contractName',
  ContractParty = 'contractParty',
  ContractScope = 'contractScope',
  ContractOwner = 'contractOwner',
  ContractEmail = 'contractEmail',
  ContractPhone = 'contractPhone',
  TermCondition = 'termCondition',
  StartDate = 'startDate',
  EndDate = 'endDate',
  UnitValue = 'unitValue',
  MainCurrency = 'mainCurrency',
  SecondCurrency = 'secondCurrency',
  Percentage = 'percentage',
  LocationName = 'locationName',
  LocationType = 'locationType',
  CityId = 'cityId',
  CityName = 'cityName',
  StateId = 'stateId',
  StateName = 'stateName',
  StateCode = 'stateCode',
  Address = 'address',
  Address1 = 'address1',
  Address2 = 'address2',
  PostalCode = 'postalCode',
  SubLocation = 'subLocation',
  DistrictName = 'districtName',
  ContactName = 'contactName',
  ContactTel = 'contactTel',
  ContactEmail = 'contactEmail',
  Timezone = 'timeZone',
  TimezoneDescription = 'timezoneDescription',
  IsHq = 'isHq',
  ServiceOffer = 'serviceOffer',
  ServiceType = 'serviceType',
  ServiceTypes = 'serviceTypes',
  ServiceTypeId = 'serviceTypeId',
  ServiceTypeIds = 'serviceTypeIds',
  ServiceTypeName = 'serviceTypeName',
  ServiceTypeConcat = 'serviceTypeConcat',
  ServiceTypeDetails = 'serviceTypeDetails',
  ServiceTypeStatus = 'serviceTypeStatus',
  ServiceConcatName = 'serviceConcatName',
  ServiceName = 'serviceName',
  TypeOfInterest = 'typeOfInterest',
  ShortName = 'shortName',
  Type = 'type',
  Roles = 'roles',
  RoleIds = 'roleIds',
  RoleId = 'roleId',
  RoleName = 'roleName',
  IsExternal = 'isExternal',
  Permissions = 'permissions',
  PermissionIds = 'permissionIds',
  PermissionId = 'permissionId',
  PermissionName = 'permissionName',
  ContactPerson = 'contactPerson',
  CountryCode = 'countryCode',
  CountryId = 'countryId',
  CountryName = 'countryName',
  Cities = 'cities',
  Currency = 'currency',
  PhoneNumber = 'phoneNumber',
  PhoneCode = 'phoneCode',
  RegionId = 'regionId',
  Region = 'region',
  SubRegionId = 'subRegionId',
  SubRegion = 'subRegion',
  SubRegions = 'subRegions',
  SiteId = 'siteId',
  Sites = 'sites',
  SiteName = 'siteName',
  SiteType = 'siteType',
  Field = 'field',
  FieldLabel = 'fieldLabel',
  FieldName = 'fieldName',
  SubsiteId = 'subsiteId',
  Subsites = 'subsites',
  Platform = 'platform',
  PlatformName = 'platformName',
  PlatformTypeId = 'platformTypeId',
  SubsiteName = 'subsiteName',
  SubsiteType = 'subsiteType',
  BlockName = 'blockName',
  FacilityTypeId = 'facilityTypeId',
  FacilityType = 'facilityType',
  IsCompanyOwner = 'isCompanyOwner',
  TimeZones = 'timeZones',
  Latitude = 'latitude',
  Longitude = 'longitude',
  ZoneName = 'zoneName',
  GmtOffset = 'gmtOffset',
  GmtOffsetName = 'gmtOffsetName',
  Abbreviation = 'abbreviation',
  TzName = 'tzName',
  Token = 'token',
  UserId = 'userId',
  VerificationUri = 'verificationUri',
  Iso = 'iso',
  Code = 'code',
  FlagImagePos = 'flagImagePos',
  Label = 'label',
  Value = 'value',
  ConvertedValue = 'convertedValue',
  Description = 'description',
  DateRange = 'dateRange',
  Layout = 'layout',
  IsApproved = 'isApproved',
  IsConfirmed = 'isConfirmed',
  IsDeleted = 'isDeleted',
  DataModel = 'dataModel',
  Remark = 'remark',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TotalWells = 'totalWells',
  TotalSubRegions = 'totalSubRegions',
  UnitMeasurement = 'unitMeasurement',
  Wells = 'wells',
  WellId = 'wellId',
  WellTypeId = 'wellTypeId',
  WellType = 'wellType',
  WellName = 'wellName',
  WellDescription = 'wellDescription',
  WellPurpose = 'wellPurpose',
  WellOriType = 'wellOriType',
  WellStructureType = 'wellStructureType',
  TypeName = 'typeName',
  Reason = 'reason',
  Purpose = 'purpose',
  Geometry = 'geometry',
  WaterDepth = 'waterDepth',
  WaterDepthConversion = 'waterDepthConversion',
  WaterDepthUnit = 'waterDepthUnit',
  Operator = 'operator',
  CompanyLogoFile = 'companyLogoFile',
  CompanyLogoUrl = 'companyLogoUrl',
  Filename = 'filename',
  Thumbnail = 'thumbnail',
  Extension = 'extension',
  File = 'file',
  FileUrl = 'fileUrl',
  ImageUrl = 'imageUrl',
  ImageFile = 'imageFile',
  IconFile = 'iconFile',
  IconUrl = 'iconUrl',
  SlotName = 'slotName',
  Tag = 'tag',
  Uwi = 'uwi',
  UwiType = 'uwiType',
  Formation = 'formation',
  Reservoir = 'reservoir',
  IsOffshore = 'isOffshore',
  IsPlatform = 'isPlatform',
  IsSubsea = 'isSubsea',
  WellborePaginated = 'wellborePaginated',
  WellboreStringsPaginated = 'wellboreStringsPaginated',
  Wellbores = 'wellbores',
  WellboreId = 'wellboreId',
  WellboreName = 'wellboreName',
  WellboreNameConcat = 'wellboreNameConcat',
  WellboreNumber = 'wellboreNumber',
  WellboreUwi = 'wellboreUwi',
  WellboreConcatName = 'wellboreConcatName',
  DeepestDepthMd = 'deepestDepthMd',
  DeepestDepthTvd = 'deepestDepthTvd',
  KickOffMd = 'kickOffMd',
  KickOffTvd = 'kickOffTvd',
  WellboreDescription = 'wellboreDescription',
  WellborePurpose = 'wellborePurpose',
  StringPurpose = 'stringPurpose',
  IsMultipleString = 'isMultipleString',
  SpudDate = 'spudDate',
  TagNumber = 'tagNumber',
  MaxAngle = 'maxAngle',
  MaxTemperature = 'maxTemperature',
  MaxTemperatureConversion = 'maxTemperatureConversion',
  MaxTemperatureUnit = 'maxTemperatureUnit',
  Elevation1 = 'elevation1',
  Elevation2 = 'elevation2',
  Elevation3 = 'elevation3',
  CompletionType = 'completionType',
  PackerFluid = 'packerFluid',
  ScssvType = 'scssvType',
  TubingType = 'tubingType',
  WellHeadType = 'wellHeadType',
  RigElevFt = 'rigElevFt',
  RigElevationFt = 'rigElevationFt',
  RigElevationFtConversion = 'rigElevationFtConversion',
  RigElevationFtUnit = 'rigElevationFtUnit',
  Pcp = 'pcp',
  PcpConversion = 'pcpConversion',
  PcpUnit = 'pcpUnit',
  Scp = 'scp',
  ScpConversion = 'scpConversion',
  ScpUnit = 'scpUnit',
  Fthp = 'fthp',
  FthpConversion = 'fthpConversion',
  FthpUnit = 'fthpUnit',
  Cithp = 'cithp',
  CithpConversion = 'cithpConversion',
  CithpUnit = 'cithpUnit',
  FluidLevelFt = 'fluidLevelFt',
  FluidLevelFtConversion = 'fluidLevelFtConversion',
  FluidLevelFtUnit = 'fluidLevelFtUnit',
  CompletionDate = 'completionDate',
  WellStringName = 'wellStringName',
  Wellstrings = 'wellstrings',
  WellStringId = 'wellStringId',
  WellboreStrings = 'wellboreStrings',
  WellboreStringId = 'wellboreStringId',
  StringIndicator = 'stringIndicator',
  StringName = 'stringName',
  Size = 'size',
  TubingSize = 'tubingSize',
  TubingSizeConversion = 'tubingSizeConversion',
  TubingSizeUnit = 'tubingSizeUnit',
  Formations = 'formations',
  FormationName = 'formationName',
  Zones = 'zones',
  TopMd = 'TopMd',
  BottomMd = 'bottomMd',
  TopTvd = 'topTvd',
  BottomTvd = 'bottomTvd',
  OriginalUnit = 'originalUnit',
  TargetUnit = 'targetUnit',
  ConversionFactor = 'conversionFactor',
  ReverseConversionFactor = 'reverseConversionFactor',
  ToolPosition = 'toolPosition',
  GeneralDescription = 'generalDescription',
  ConcatServiceName = 'concatServiceName',
  Index = 'index',
  SortIndex = 'sortIndex',
  IsCompleted = 'isCompleted',
  ProjectId = 'projectId',
  ProjectName = 'projectName',
  ServiceTypePackages = 'serviceTypePackages',
  ServiceAndPackages = 'serviceAndPackages',
  ProjectWellbores = 'projectWellbores',
  ProjectServiceTypePackages = 'projectServiceTypePackages',
  ReportSections = 'reportSections',
  ProjectReportSections = 'projectReportSections',
  PackageId = 'packageId',
  PackageName = 'packageName',
  SectionId = 'sectionId',
  ProjectPlan = 'projectPlan',
  PlanId = 'planId',
  PlanStartDate = 'planStartDate',
  PlanEndDate = 'planEndDate',
  PreparedBy = 'preparedBy',
  ReviewedBy = 'reviewedBy',
  ApprovedBy = 'approvedBy',
  CompletenessPercentage = 'completenessPercentage',
  SectionCompleted = 'sectionCompleted',
  DiagramName = 'diagramName',
  Base64Image = 'base64Image',
  Base64ImageWithLabel = 'base64ImageWithLabel',
  ToolId = 'toolId',
  PceDiagramData = 'pceDiagramData',
  ImageHeight = 'imageHeight',
  Offset = 'offset',
  SerialNo = 'serialNo',
  BottomConnection = 'bottomConnection',
  TopConnection = 'topConnection',
  IdIn = 'idIn',
  PressureRating = 'pressureRating',
  PressureRatingConversion = 'pressureRatingConversion',
  PressureRatingUnit = 'pressureRatingUnit',
  TemperatureRating = 'temperatureRating',
  TemperatureRatingConversion = 'temperatureRatingConversion',
  TemperatureRatingUnit = 'temperatureRatingUnit',
  Length = 'length',
  LengthConversion = 'lengthConversion',
  LengthUnit = 'lengthUnit',
  Weight = 'weight',
  WeightConversion = 'weightConversion',
  WeightUnit = 'weightUnit',
  Service = 'service',
  Objective = 'objective',
  GeneralObjective = 'generalObjective',
  SpecificObjectives = 'specificObjectives',
  SpecificObjectiveId = 'specificObjectiveId',
  Item = 'item',
  ItemName = 'itemName',
  GeneralSafeties = 'generalSafeties',
  Content = 'content',
  JobCategory = 'jobCategory',
  IsStringLevel = 'isStringLevel',
  WellboreLevel = 'wellboreLevel',
  WellboreHistories = 'wellboreHistories',
  WellboreLevelDetail = 'wellboreLevelDetail',
  WellboreHistoryDetails = 'wellboreHistoryDetails',
  Information = 'information',
  StringLevels = 'stringLevels',
  StringLevelDetails = 'stringLevelDetails',
  PceDiagramDetails = 'pceDiagramDetails',
  ProcedureId = 'procedureId',
  ProcedureDetails = 'procedureDetails',
  ProcedureDetailId = 'procedureDetailId',
  TargetStartDate = 'targetStartDate',
  TargetStartTime = 'targetStartTime',
  TargetEndDate = 'targetEndDate',
  TargetEndTime = 'targetEndTime',
  ActivityStartDate = 'activityStartDate',
  ActivityStartTime = 'activityStartTime',
  ActivityEndDate = 'activityEndDate',
  ActivityEndTime = 'activityEndTime',
  SequenceNo = 'sequenceNo',
  MainStepId = 'mainStepId',
  MainStep = 'mainStep',
  MainSteps = 'mainSteps',
  SubStepId = 'subStepId',
  SubStep = 'subStep',
  SubSteps = 'subSteps',
  SubStepDescription = 'substepDescription',
  DetailActivities = 'detailActivities',
  DetailActivityId = 'detailActivityId',
  Detail = 'detail',
  Duration = 'duration',
  CustomDuration = 'customDuration',
  TotalDuration = 'totalDuration',
  TotalNptDuration = 'totalNptDuration',
  CumHrs = 'cumHrs',
  TimeFrom = 'timeFrom',
  TimeTo = 'timeTo',
  Toolstring = 'toolstring',
  ProjectPlanToolstringData = 'projectPlanToolstringData',
  ProjectActualToolstringData = 'projectActualToolstringData',
  OdIn = 'odIn',
  FishNeck = 'fishneck',
  Temperature = 'temperature',
  ActualDailyReports = 'actualDailyReports',
  ReportName = 'reportName',
  ReportDate = 'reportDate',
  ReportStartDate = 'reportStartDate',
  ReportEndDate = 'reportEndDate',
  TotalReport = 'totalReport',
  PageId = 'pageId',
  PageIds = 'pageIds',
  PageName = 'pageName',
  PageDetails = 'pageDetails',
  PagePermissionDetails = 'pagePermissionDetails',
  PageStatus = 'pageStatus',
  PersonnelOnBoardDetails = 'personnelOnBoardDetails',
  DateOnBoard = 'dateOnBoard',
  DateReturnToBase = 'dateReturnToBase',
  Shift = 'shift',
  Position = 'position',
  WellDetail = 'wellDetail',
  ActualEventDetail = 'actualEventDetail',
  ActualId = 'actualId',
  ActivityDescription = 'activityDescription',
  PlanDays = 'planDays',
  JobStatus = 'jobStatus',
  JobStartDate = 'jobStartDate',
  JobEndDate = 'jobEndDate',
  DailyCost = 'dailyCost',
  DailyCostCurrency = 'dailyCostCurrency',
  PreviousCumulativeCost = 'previousCumulativeCost',
  PreviousCumulativeCostCurrency = 'previousCumulativeCostCurrency',
  ActualCumulativeCost = 'actualCumulativeCost',
  ActualCumulativeCostCurrency = 'actualCumulativeCostCurrency',
  Operation = 'operation',
  OperationHighlightDetails = 'operationHighlightDetails',
  WellPlanObjective = 'wellPlanObjective',
  LookaheadPlanDetails = 'lookaheadPlanDetails',
  PartNumber = 'partNumber',
  EquipmentLeftInHoleDetails = 'equipmentLeftInHoleDetails',
  VesselName = 'vesselName',
  SupportCraftDetails = 'supportCraftDetails',
  ActualSignificantFindings = 'actualSignificantFindings',
  ActualHSSEIssues = 'actualHSSEIssues',
  Findings = 'findings',
  ImmediateActionTaken = 'immediateActionTaken',
  Issue = 'issue',
  PhaseCode = 'phaseCode',
  MLPhaseCode = 'mlPhaseCode',
  ConfigurationPhaseCodes = 'configurationPhaseCodes',
  ActivityCode = 'activityCode',
  IsNPT = 'isNPT',
  NptHrs = 'nptHrs',
  WellInterventionRepresentative = 'wellInterventionRepresentative',
  ClientRepresentative = 'clientRepresentative',
  TotalPercentage = 'totalPercentage',
  SectionItems = 'sectionItems',
  GeneralInformation = 'generalInformation',
  ToolstringFormDetail = 'toolstringFormDetail',
  PceFormDetail = 'pceFormDetail',
  SubCategory = 'subCategory',
  CategoryName = 'categoryName',
  EquipmentId = 'eqptId',
  EquipmentMainId = 'eqptMainId',
  EquipmentCategoryId = 'eqptCatId',
  EquipmentDocs = 'eqptDocs',
  ManufacturerId = 'manufacturerId',
  ManufacturerName = 'manufacturerName',
  ManufacturerSerialNumber = 'manufacturerSerialNumber',
  GeneralRemark = 'generalRemark',
  PurchaseDate = 'purchaseDate',
  Cost = 'cost',
  Model = 'model',
  OwnershipStatus = 'owernershipStatus',
  Capacity = 'capacity',
  Specification = 'specification',
  Dimension = 'dimension',
  Application = 'application',
  InternalDiameter = 'internalDiameter',
  InternalDiameterConversion = 'internalDiameterConversion',
  InternalDiameterUnit = 'internalDiameterUnit',
  OuterDiameter = 'outerDiameter',
  OuterDiameterConversion = 'outerDiameterConversion',
  OuterDiameterUnit = 'outerDiameterUnit',
  ComponentOrder = 'componentOrder',
  ComponentPosition = 'componentPosition',
  EnvironmentServices = 'environmentServices',
  OtherDetails = 'otherDetails',
  DocumentName = 'documentName',
  DocumentUrl = 'documentUrl',
  DocumentType = 'documentType',
  SerialNumberDetails = 'serialNumberDetails',
  BookmarkTitle = 'bookmarkTitle',
  BookmarkUrl = 'url',
  Title = 'title',
  ModelType = 'modelType',
  ModelName = 'modelName',
  Files = 'files',
  OnDemandMainId = 'onDemandMainId',
  OnDemandFileId = 'onDemandFileId',
  FileName = 'fileName',
  TotalPages = 'totalPages',
  SelectedPages = 'selectedPages',
  PageNo = 'pageNo',
  PageNoLabel = 'pageNoLabel',
  PageExtracted = 'pageExtracted',
  CreatedBy = 'createdBy',
  ProgressUpload = 'progressUpload',
  PageExtractions = 'pageExtractions',
  IsSuccess = 'isSuccess',
  StatusCode = 'statusCode',
  ExtractedData = 'extractedData',
  FieldData = 'fieldData',
  ExtractPageRequests = 'extractPageRequests',
  FieldValue = 'fieldValue',
  Confidence = 'confidence',
  ExportType = 'exportType',
  SearchPagesQuery = 'searchPagesQuery',
  SearchQuery = 'searchQuery',
  FieldConfidence = 'fieldConfidence',
  IsTable = 'isTable',
  FieldBoundary = 'fieldBoundary',
  PageNumber = 'page_number',
  Polygon = 'polygon',
  X = 'x',
  Y = 'y',
  FieldSpan = 'fieldSpan',
  RowLabel = 'rowLabel',
  RowNo = 'rowNo',
  RowValue = 'rowValue',
  ColLabel = 'colLabel',
  ColName = 'colName',
  ColValue = 'colValue',
  ColBoundary = 'colBoundary',
  ColSpan = 'colSpan',
  Coords = 'coords',
  Top = 'top',
  Left = 'left',
  Width = 'width',
  Height = 'height'
}