import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { EnvironmentProviders, Provider, inject } from '@angular/core';
import { globalErrorInterceptor } from 'app/core/error/global-error.interceptor';
import { HandleErrorService } from 'app/core/error/handle-error.service';

export const provideGlobalError = (): Array<Provider | EnvironmentProviders> => {
  return [
    provideHttpClient(withInterceptors([globalErrorInterceptor])),
    {
      provide: HTTP_INTERCEPTORS,
      useValue: () => inject(HandleErrorService),
      multi: true
    }
  ];
};
