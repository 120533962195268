import { CustomType } from "app/core/shared/shared.type";

export const applicationStatusData: CustomType[] = [
  {
    id: '1',
    label: 'Active',
    value: true
  },
  {
    id: '2',
    label: 'Inactive',
    value: false
  }
]

export const issueStatusData: CustomType[] = [
  {
    id: '1',
    label: 'Open',
    value: 'Open'
  },
  {
    id: '2',
    label: 'Close',
    value: 'close'
  }
]
