import { CustomType } from "app/core/shared/shared.type";

export const extractionModelData: CustomType[] = [
  {
    id: '1',
    label: 'Alarm Rationalization Model',
    value: 1
  },
  {
    id: '2',
    label: 'IPF Classification Model',
    value: 2
  },
  // {
  //   id: '3',
  //   label: 'IPF Verification Model',
  //   value: 3
  // },
  {
    id: '4',
    label: 'FNG Detector Model',
    value: 4
  },
  {
    id: '5',
    label: 'CBM Lube Oil Analysis Model',
    value: 5
  },
  {
    id: '6',
    label: 'CBM Vibration Report Model',
    value: 6
  }
]