import { CustomType } from "app/core/shared/shared.type";

export const dataModelData: CustomType[] = [
  {
    id: 'pce',
    label: 'PCE',
    value: 'PCE'
  },
  {
    id: 'ts',
    label: 'Toolstring',
    value: 'Toolstring'
  }
]
