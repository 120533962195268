import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { CompanyEquipmentGeneralInformationForm, CompanyEquipmentRelatedDocumentForm } from 'app/core/shared/types/company/company-equipment-inventory.type';

@Injectable({ providedIn: 'root' })
export class CompanyEquipmentsMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/data-management/company/equipment')
      .reply(() => {
        // Generate a new equipment
        const newEquipment: CompanyEquipmentGeneralInformationForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: '',
          [Field.EquipmentCategoryId]: '',
          [Field.ServiceTypeIds]: [],
          [Field.ManufacturerId]: '',
          [Field.CountryId]: 135,
          [Field.Name]: '',
          [Field.ShortName]: '',
          [Field.Description]: '',
          [Field.GeneralRemark]: '',
          [Field.PurchaseDate]: '',
          [Field.Cost]: null as number,
          [Field.Currency]: 'USD',
          [Field.Model]: '',
          [Field.OwnershipStatus]: '',
          [Field.Owner]: '',
          [Field.Capacity]: '',
          [Field.Specification]: '',
          [Field.Dimension]: '',
          [Field.Application]: '',
        };

        // Return the response
        return [200, newEquipment];
      });

    this._fuseMockApiService
      .onPost('api/data-management/company/equipment/document')
      .reply(() => {
        // Generate a new document
        const newDocument: CompanyEquipmentRelatedDocumentForm = {
          [Field.ID]: 'new',
          [Field.EquipmentMainId]: null,
          [Field.DocumentName]: '',
          [Field.File]: null,
          [Field.Remark]: null,
          [Field.IsActive]: true,
        };

        // Return the response
        return [200, newDocument];
      });
  }
}
