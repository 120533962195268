import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { CompanyWellForm } from 'app/core/shared/types//company/well/well.type';
import { CompanyWellboreForm } from 'app/core/shared/types//company/well/wellbore.type';
import { CompanyWellboreStringForm } from 'app/core/shared/types//company/well/wellbore-string.type';

@Injectable({ providedIn: 'root' })
export class CompanyWellWellboreWellboreStringMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/data-management/company/well')
      .reply(() => {
        // Generate a new well
        const newWell: CompanyWellForm = {
          [Field.ID]: 'new',
          [Field.SubsiteId]: null,
          [Field.WellTypeId]: null,
          [Field.WellName]: '',
          [Field.ShortName]: null,
          [Field.WellDescription]: null,
          [Field.WellOriType]: null,
          [Field.WellStructureType]: 1,
          [Field.Purpose]: null,
          [Field.WaterDepth]: 0,
          [Field.WaterDepthConversion]: 0,
          [Field.WaterDepthUnit]: 'ft',
          [Field.OperatorOrContractorId]: null,
          [Field.SlotName]: null,
          [Field.Tag]: null,
          [Field.Uwi]: null,
          [Field.UwiType]: null,
          [Field.Reservoir]: null,
          [Field.Latitude]: 0,
          [Field.Longitude]: 0,
          [Field.IsActive]: false
        };

        // Return the response
        return [200, newWell];
      });

    this._fuseMockApiService
      .onPost('api/data-management/company/wellbore')
      .reply(() => {
        // Generate a new wellbore
        const newWellbore: CompanyWellboreForm = {
          [Field.ID]: 'new',
          [Field.SubsiteId]: null,
          [Field.WellId]: null,
          [Field.WellboreName]: '',
          [Field.ShortName]: null,
          [Field.WellboreNumber]: null,
          [Field.WellHeadType]: null,
          [Field.WellboreUwi]: null,
          [Field.DeepestDepthMd]: 0,
          [Field.DeepestDepthTvd]: 0,
          [Field.KickOffMd]: 0,
          [Field.KickOffTvd]: 0,
          [Field.UnitMeasurement]: 'ft',
          [Field.WellboreDescription]: null,
          [Field.WellborePurpose]: null,
          [Field.IsMultipleString]: false,
          [Field.SpudDate]: null,
          [Field.CompletionDate]: null,
          [Field.EndDate]: null,
          [Field.Latitude]: 0,
          [Field.Longitude]: 0,
          [Field.Remark]: null,
          [Field.IsActive]: false,
          [Field.TagNumber]: null,
          [Field.MaxAngle]: 0,
          [Field.MaxTemperature]: 0,
          [Field.MaxTemperatureConversion]: 0,
          [Field.MaxTemperatureUnit]: 'F',
          [Field.Elevation1]: 0,
          [Field.Elevation2]: 0,
          [Field.Elevation3]: 0,
          [Field.CompletionType]: null,
          [Field.PackerFluid]: null
        };

        // Return the response
        return [200, newWellbore];
      });

    this._fuseMockApiService
      .onPost('api/data-management/company/wellboreString')
      .reply(() => {
        // Generate a new wellboreString
        const newWellboreString: CompanyWellboreStringForm = {
          [Field.ID]: 'new',
          [Field.WellboreId]: null,
          [Field.StringIndicator]: null,
          [Field.StringName]: '',
          [Field.ShortName]: null,
          [Field.TubingSize]: 0,
          [Field.TubingSizeConversion]: 0,
          [Field.TubingSizeUnit]: 'ft',
          [Field.Uwi]: null,
          [Field.TagNumber]: null,
          [Field.MaxAngle]: 0,
          [Field.MaxTemperature]: 0,
          [Field.MaxTemperatureConversion]: 0,
          [Field.MaxTemperatureUnit]: 'F',
          [Field.Purpose]: null,
          [Field.Description]: null,
          [Field.Remark]: null,
          [Field.IsActive]: false,
          [Field.ScssvType]: null,
          [Field.TubingType]: null,
          [Field.RigElevFt]: 0
        };

        // Return the response
        return [200, newWellboreString];
      });
  }
}
