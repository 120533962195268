import { TimeZoneGMT } from "app/core/shared/shared.type";

export const timezoneData: TimeZoneGMT[] = [
  {
    'id': 'utc-12',
    'label': 'UTC-12:00',
    'value': 'UTC-12:00',
    'cities': ['Baker Island, United States', 'Howland Island, United States']
  },
  {
    'id': 'utc-11',
    'label': 'UTC-11:00',
    'value': 'UTC-11:00',
    'cities': ['Pago Pago, American Samoa', 'Alofi, Niue']
  },
  {
    'id': 'utc-10',
    'label': 'UTC-10:00',
    'value': 'UTC-10:00',
    'cities': ['Honolulu, United States', 'Papeete, French Polynesia']
  },
  {
    'id': 'utc-09',
    'label': 'UTC-09:00',
    'value': 'UTC-09:00',
    'cities': ['Anchorage, United States', 'Juneau, United States']
  },
  {
    'id': 'utc-08',
    'label': 'UTC-08:00',
    'value': 'UTC-08:00',
    'cities': ['Los Angeles, United States', 'Vancouver, Canada']
  },
  {
    'id': 'utc-07',
    'label': 'UTC-07:00',
    'value': 'UTC-07:00',
    'cities': ['Denver, United States', 'Mexico City, Mexico']
  },
  {
    'id': 'utc-06',
    'label': 'UTC-06:00',
    'value': 'UTC-06:00',
    'cities': ['Chicago, United States', 'Guatemala City, Guatemala']
  },
  {
    'id': 'utc-05',
    'label': 'UTC-05:00',
    'value': 'UTC-05:00',
    'cities': ['New York City, United States', 'Bogotá, Colombia']
  },
  {
    'id': 'utc-04',
    'label': 'UTC-04:00',
    'value': 'UTC-04:00',
    'cities': ['Caracas, Venezuela', 'La Paz, Bolivia']
  },
  {
    'id': 'utc-03',
    'label': 'UTC-03:00',
    'value': 'UTC-03:00',
    'cities': ['Buenos Aires, Argentina', 'Santiago, Chile']
  },
  {
    'id': 'utc-02',
    'label': 'UTC-02:00',
    'value': 'UTC-02:00',
    'cities': ['Stanley, Falkland Islands', 'South Georgia and the South Sandwich Islands']
  },
  {
    'id': 'utc-01',
    'label': 'UTC-01:00',
    'value': 'UTC-01:00',
    'cities': ['Azores, Portugal', 'Ponta Delgada, Portugal']
  },
  {
    'id': 'utc-00',
    'label': 'UTC±00:00 (GMT)',
    'value': 'UTC±00:00',
    'cities': ['London, United Kingdom', 'Dublin, Ireland']
  },
  {
    'id': 'utc+01',
    'label': 'UTC+01:00',
    'value': 'UTC+01:00',
    'cities': ['Paris, France', 'Berlin, Germany']
  },
  {
    'id': 'utc+02',
    'label': 'UTC+02:00',
    'value': 'UTC+02:00',
    'cities': ['Athens, Greece', 'Cairo, Egypt']
  },
  {
    'id': 'utc+03',
    'label': 'UTC+03:00',
    'value': 'UTC+03:00',
    'cities': ['Moscow, Russia', 'Riyadh, Saudi Arabia']
  },
  {
    'id': 'utc+04',
    'label': 'UTC+04:00',
    'value': 'UTC+04:00',
    'cities': ['Dubai, United Arab Emirates', 'Baku, Azerbaijan']
  },
  {
    'id': 'utc+05',
    'label': 'UTC+05:00',
    'value': 'UTC+05:00',
    'cities': ['Islamabad, Pakistan', 'Tashkent, Uzbekistan']
  },
  {
    'id': 'utc+06',
    'label': 'UTC+06:00',
    'value': 'UTC+06:00',
    'cities': ['Almaty, Kazakhstan', 'Dhaka, Bangladesh']
  },
  {
    'id': 'utc+07',
    'label': 'UTC+07:00',
    'value': 'UTC+07:00',
    'cities': ['Bangkok, Thailand', 'Jakarta, Indonesia']
  },
  {
    'id': 'utc+08',
    'label': 'UTC+08:00',
    'value': 'UTC+08:00',
    'cities': ['Kuala Lumpur, Malaysia', 'Beijing, China', 'Singapore, Singapore']
  },
  {
    'id': 'utc+09',
    'label': 'UTC+09:00',
    'value': 'UTC+09:00',
    'cities': ['Tokyo, Japan', 'Seoul, South Korea']
  },
  {
    'id': 'utc+10',
    'label': 'UTC+10:00',
    'value': 'UTC+10:00',
    'cities': ['Sydney, Australia', 'Brisbane, Australia']
  },
  {
    'id': 'utc+11',
    'label': 'UTC+11:00',
    'value': 'UTC+11:00',
    'cities': ['Honiara, Solomon Islands', 'Noumea, New Caledonia']
  },
  {
    'id': 'utc+12',
    'label': 'UTC+12:00',
    'value': 'UTC+12:00',
    'cities': ['Suva, Fiji', 'Funafuti, Tuvalu']
  }
]