import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { UserAccountForm } from 'app/core/shared/types/user.type';

@Injectable({ providedIn: 'root' })
export class ManageUserMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/assistance/users/account')
      .reply(() => {
        // Generate a new user account
        const newUserAccount: UserAccountForm = {
          [Field.UserId]: 'new',
          [Field.FirstName]: 'New',
          [Field.LastName]: 'User',
          [Field.Email]: null,
          [Field.RoleIds]: null
        };

        // Return the response
        return [200, newUserAccount];
      });
  }
}
