import { CustomType } from "app/core/shared/shared.type";

export const wellOriginalTypeData: CustomType[] = [
  {
    id: '1',
    label: 'Gas Injector',
    value: 1
  },
  {
    id: '2',
    label: 'Gas Producer',
    value: 2
  },
  {
    id: '3',
    label: 'Oil Producer',
    value: 3
  },
  {
    id: '4',
    label: 'Water Injector',
    value: 4
  }
]
