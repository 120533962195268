import { CustomType } from "app/core/shared/shared.type";

export const currencyData: CustomType[] = [
  {
    id: 'USD',
    label: 'USD',
    value: 4.67,
    description: 'Dollar Currency'
  },
  {
    id: 'MYR',
    label: 'MYR',
    value: 0.21,
    description: 'Ringgit Currency'
  }
]
