import { CustomType } from "app/core/shared/shared.type";

export const stringIndicatorData: CustomType[] = [
  {
    id: '1',
    label: 'LS - Long String',
    value: 'LS'
  },
  {
    id: '2',
    label: 'SS - Short String',
    value: 'SS'
  },
  {
    id: '3',
    label: 'TS - Single String',
    value: 'TS'
  }
]
