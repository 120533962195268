import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { CompanyOfficeForm } from 'app/core/shared/types/company/company.type';

@Injectable({ providedIn: 'root' })
export class CompanyFacilitiesMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/data-management/company/facility')
      .reply(() => {
        // Generate a new facility aka office
        const newFacility: CompanyOfficeForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: null,
          [Field.FacilityTypeId]: null,
          [Field.LocationName]: '',
          [Field.LocationType]: null,
          [Field.CityId]: null,
          [Field.StateId]: null,
          [Field.CountryId]: 135,
          [Field.Address1]: null,
          [Field.Address2]: null,
          [Field.DistrictName]: null,
          [Field.PostalCode]: null,
          [Field.Longitude]: 0,
          [Field.Latitude]: 0,
          [Field.RegionId]: null,
          [Field.SubRegionId]: null,
          [Field.ContactName]: null,
          [Field.PhoneCode]: '+60',
          [Field.ContactTel]: null,
          [Field.ContactEmail]: null,
          [Field.Timezone]: null,
          [Field.TimezoneDescription]: null,
          [Field.IsHq]: false
        };

        // Return the response
        return [200, newFacility];
      });
  }
}
