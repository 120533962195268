import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { take } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { User, UserCurrentResp } from 'app/core/shared/types/user.type';
import { superNavigation, userNavigation, unknownNavigation, subNavigation } from '360kit/data-store/navigation-menu.data';
import { UserTypeEnum } from 'app/core/user/user.enum';
import { UserService } from 'app/core/user/user.service';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi {
  private readonly _superNavigation: FuseNavigationItem[] = superNavigation;
  private readonly _userNavigation: FuseNavigationItem[] = userNavigation;
  private readonly _unknownNavigation: FuseNavigationItem[] = unknownNavigation;
  private readonly _subNavigation: FuseNavigationItem[] = subNavigation;

  private _itemNavigation: FuseNavigationItem[] = [];

  /**
   * Constructor
   */
  constructor(
    private _fuseMockApiService: FuseMockApiService,
    private _userService: UserService
  ) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Navigation - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onGet('api/common/navigation')
      .reply(() => {
        this._userService.user$
          .pipe(take(1))
          .subscribe((user: User) => {
            if (user.userType === UserTypeEnum.INTERNAL) {
              this._itemNavigation = this._superNavigation;
            } else if (user.userType ===  UserTypeEnum.EXTERNAL) {
              this._itemNavigation = this._userNavigation;
            } else {
              this._itemNavigation = this._unknownNavigation;
            }

            this._itemNavigation.forEach((horizontalNavItem) => {
              this._subNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === horizontalNavItem.id) {
                  horizontalNavItem.children = cloneDeep(defaultNavItem.children);

                  if (defaultNavItem.id === 'space-route') {
                    this._userService.spaceRouteClone.next(cloneDeep(defaultNavItem.children));
                  }
                }
              });
            });
            
            // Filter Route Menu based on User Roles
            this._itemNavigation = this.filterRouteNavigationBasedOnRouteItem(this._itemNavigation, user);
          });

        // Return the response
        return [
          200,
          {
            default: cloneDeep(this._itemNavigation),
            horizontal: cloneDeep(this._itemNavigation)
          },
        ];
      });
  }

  filterRouteNavigationBasedOnRouteItem(navigationItem: FuseNavigationItem[], userData: User): FuseNavigationItem[] {
    if (userData.userType === UserTypeEnum.INTERNAL) {
      return navigationItem;
    } else if (userData.userType === UserTypeEnum.EXTERNAL) {
      const filteredNavigation: FuseNavigationItem[] = [];

      for (const item of navigationItem) {
        const filteredChildren: FuseNavigationItem[] = [];

        if (item.children) {
          for (const child of item.children) {
            if (child.accessRole) {
              if (child.accessRole.some(role => userData.roles.includes(role))) {
                filteredChildren.push(child);
              }
            } else {
              filteredChildren.push(child);
            }
          }
        }

        if (filteredChildren.length > 0) {
          const filteredGroup: FuseNavigationItem = { ...item, children: filteredChildren };
          filteredNavigation.push(filteredGroup);
        }
      }
      return filteredNavigation;
    }
    return [];
  }

  filterRouteNavigationBasedOnRouteGroup(navigationItem: FuseNavigationItem[], userData: User): FuseNavigationItem[] {
    return navigationItem.filter((item) => {
      if (userData.userType === UserTypeEnum.INTERNAL) {
        return true;
      }

      if (userData.userType === UserTypeEnum.EXTERNAL) {
        if (item.accessRole && userData.roles) {
          return item.accessRole.some((allowedRole) => userData.roles.includes(allowedRole));
        } else {
          return true;
        }
      }

      return false;
    });
  }
}
