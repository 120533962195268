import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { HandleErrorService } from 'app/core/error/handle-error.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const globalErrorInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const handleErrorService = inject(HandleErrorService);

  // Response
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      handleErrorService.handleError(error);
      // Rethrow the error to propagate it to the calling code
      throw error;
    })
  );
};
