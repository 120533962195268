import { CustomType } from "app/core/shared/shared.type";

export const serviceOfferData: CustomType[] = [
  {
    id: '1',
    label: 'Exploration and Production (E&P) Services',
    value: 'Exploration and Production (E&P) Services',
    description: 'Offering solutions for oil and gas exploration, drilling, reservoir modeling, and production optimization.'
  },
  {
    id: '2',
    label: 'Seismic Surveys',
    value: 'Seismic Surveys',
    description: 'Providing geophysical data collection and analysis for locating and characterizing subsurface oil and gas reserves.'
  },
  {
    id: '3',
    label: 'Reservoir Engineering',
    value: 'Reservoir Engineering',
    description: 'Offering expertise in assessing reservoir properties, fluid flow dynamics, and reservoir simulation for efficient production.'
  },
  {
    id: '4',
    label: 'Well Logging and Formation Evaluation',
    value: 'Well Logging and Formation Evaluation',
    description: 'Conducting well log analysis to determine rock properties, fluid types, and potential reservoir zones.'
  },
  {
    id: '5',
    label: 'Well Services',
    value: 'Well Services',
    description: 'Including well completion, cementing, perforation, and other activities that prepare wells for production.'
  },
  {
    id: '6',
    label: 'Oilfield Equipment Rental',
    value: 'Oilfield Equipment Rental',
    description: 'Renting out drilling rigs, mud pumps, wellheads, and other equipment required for drilling and production.'
  },
  {
    id: '7',
    label: 'Health, Safety, and Environment (HSE) Services',
    value: 'Health, Safety, and Environment (HSE) Services',
    description: 'Providing solutions to ensure safe working conditions, compliance with environmental regulations, and managing operational risks.'
  },
  {
    id: '8',
    label: 'Enhanced Oil Recovery (EOR) Services',
    value: 'Enhanced Oil Recovery (EOR) Services',
    description: 'Offering technologies to maximize oil recovery from reservoirs using methods such as water flooding, gas injection, or chemical processes.'
  },
  {
    id: '9',
    label: 'Pipeline and Transportation Services',
    value: 'Pipeline and Transportation Services',
    description: 'Designing, building, and maintaining pipelines and transportation infrastructure for oil and gas distribution.'
  },
  {
    id: '10',
    label: 'Consulting and Training',
    value: 'Consulting and Training',
    description: 'Providing expert advice and training to petroleum companies in various areas such as project management, regulatory compliance, and technology integration.'
  }
];