<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <img class="w-32" src="assets/images/logo/logo-text-white.png" alt="Xtralisis">
      </div>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div sticky
    class="relative bg-primary dark flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <div class="flex items-center mx-2 mr-4 lg:mr-8">
        <div class="hidden lg:flex">
          <img class="dark:hidden w-24" src="assets/images/icons/icon-152x152.png" alt="Xtralisis">
          <img class="hidden dark:flex w-32" src="assets/images/logo/logo-text-white.png" alt="Xtralisis">
        </div>
        <img class="flex lg:hidden w-20 p-4" src="assets/images/icons/icon-512x512-white.png" alt="Xtralisis">
      </div>
      <!-- Horizontal navigation -->
      <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'"
        [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
      </button>
    </ng-container>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <languages></languages>
      <search [appearance]="'bar'"></search> -->
      <!-- <bookmarks></bookmarks> -->
      <notifications></notifications>
      <user [showAvatar]="false" [routeSpace]="navigationSpace.children"></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center w-full h-14 sm:h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary">Xtralisis &copy; {{currentYear}}</span>
  </div>
</div>