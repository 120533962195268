import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class HandleErrorService {

  /**
   * Constructor
   */
  constructor(private _toastrService: ToastrService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Handling HTTP error using toastr
   * 
   * @param err
   */
  handleError(err: HttpErrorResponse) {
    let errorMsg: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occured. Handle it accordingly.
      errorMsg = `An error occured: ${err.error.message}`;
    } else {
      const errMsg = err.error?.message;
      // The backend return an unsuccessful response code
      switch (err.status) {
        case 400:
          errorMsg = `${err.status}: ${errMsg ? errMsg : `Bad Request. The request is malformed or missing required parameters.`}`;
          break;
        case 401:
          errorMsg = `${err.status}: ${errMsg ? errMsg : `You are unauthorized to perform this action.`}`;
          break;
        case 403:
          errorMsg = `${err.status}: ${errMsg ? errMsg : `Access to this resource is forbidden.`}`;
          break;
        case 404:
          errorMsg = `${err.status}: ${errMsg ? errMsg : `The requested resource was not found.`}`;
          break;
        case 500:
          errorMsg = `${err.status}: ${errMsg ? errMsg : `Internal Server Error. Something went wrong on the server.`}`;
          break;
        case 502:
          errorMsg = `${err.status}: ${errMsg ? errMsg : `Bad Gateway. The server encountered an error while processing your request.`}`;
          break;
        case 503:
          errorMsg = `${err.status}: ${errMsg ? errMsg : `Service Unavailable. The server is temporarily unable to respond to your request.`}`;
          break;
        case 504:
          errorMsg = `${err.status}: ${errMsg ? errMsg : `Gateway Timeout. The server did not receive a response from the upstream server.`}`;
          break;
        default:
          errorMsg = `Something went wrong!`;
      }
    }
    this._toastrService.error(errorMsg);
  }
}
