import { CustomType } from "app/core/shared/shared.type";

export const locationTypeData: CustomType[] = [
  {
    id: '1',
    label: 'Offshore',
    value: 1
  },
  {
    id: '2',
    label: 'Land',
    value: 2
  }
]
