import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, CSP_NONCE, importProvidersFrom } from '@angular/core';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { provideNgxMask } from 'ngx-mask';
import { provideFuse } from '@fuse';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { provideGlobalError } from 'app/core/error/global-error.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
import { appRoutes } from 'app/app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),

    // Material Date Adapter
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'DDD',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
    {
      provide: CSP_NONCE,
      useValue: globalThis.anyNonceValue
    },

    // Transloco Config
    provideTransloco(),

    // Import Service Worker module
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', {
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
        enabled: environment.production
      }),
    ),

    // Import Toastr module
    importProvidersFrom(
      ToastrModule.forRoot({
        timeOut: 6500,
        preventDuplicates: true
      })
    ),

    // Import provideNgxMask
    provideNgxMask(),

    // Global error handling
    provideGlobalError(),

    // Fuse
    provideAuth(),
    provideIcons(),
    provideFuse({
      mockApi: {
        delay: 0,
        services: mockApiServices,
      },
      fuse: {
        layout: 'modern',
        scheme: 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px',
        },
        theme: 'theme-xtralisis',
        themes: [
          {
            id: 'theme-default',
            name: 'Default',
          },
          {
            id: 'theme-brand',
            name: 'Brand',
          },
          {
            id: 'theme-teal',
            name: 'Teal',
          },
          {
            id: 'theme-rose',
            name: 'Rose',
          },
          {
            id: 'theme-purple',
            name: 'Purple',
          },
          {
            id: 'theme-amber',
            name: 'Amber',
          },
          {
            id: 'theme-xtralisis',
            name: 'Xtralisis',
          },
        ],
      },
    }),
  ],
};
