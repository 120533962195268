import { CustomType } from "app/core/shared/shared.type";

export const jobStatusData: CustomType[] = [
  {
    id: '1',
    label: 'Completed',
    value: 'Completed'
  },
  {
    id: '2',
    label: 'In Progress',
    value: 'InProgress'
  },
  {
    id: '3',
    label: 'Suspended',
    value: 'Suspended'
  }
]
