import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { ExtractionForm } from 'app/core/shared/types/project/extraction.type';

@Injectable({ providedIn: 'root' })
export class ExtractionMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/projects/extraction')
      .reply(() => {
        // Generate a new extraction
        const newExtraction: ExtractionForm = {
          [Field.ID]: 'new',
          [Field.Model]: null as number,
          [Field.Title]: 'New Model Name'
        };

        // Return the response
        return [200, newExtraction];
      });
  }
}
