import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { EquipmentManufacturerForm } from 'app/core/shared/types/metadata/equipment-manufacturer.type';

@Injectable({ providedIn: 'root' })
export class MetadataEquipmentManufacturersMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/configurations/metadata/equipment-manufacturer')
      .reply(() => {
        // Generate a new service type
        const newEquipmentManufacturer: EquipmentManufacturerForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: null,
          [Field.Name]: '',
          [Field.CompanyLogoUrl]: null,
          [Field.CityId]: null,
          [Field.StateId]: null,
          [Field.CountryId]: 135,
          [Field.Address1]: null,
          [Field.Address2]: null,
          [Field.PostalCode]: null,
          [Field.PhoneCode]: '+60',
          [Field.ContactTel]: null,
          [Field.Email]: null,
        };

        // Return the response
        return [200, newEquipmentManufacturer];
      });
  }
}
