import { CustomType } from "app/core/shared/shared.type";

export const permissionPageData: CustomType[] = [
  {
    id: '1',
    label: 'VIEW',
    value: 'View'
  },
  {
    id: '2',
    label: 'CREATE',
    value: 'Create'
  },
  {
    id: '3',
    label: 'UPDATE',
    value: 'Update'
  },
  {
    id: '4',
    label: 'DELETE',
    value: 'Delete'
  },
  {
    id: '5',
    label: 'PRINT',
    value: 'Print'
  },
  {
    id: '6',
    label: 'APPROVAL',
    value: 'Approval'
  }
]
