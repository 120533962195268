import { AcademyMockApi } from 'app/mock-api/apps/academy/api';
import { ChatMockApi } from 'app/mock-api/apps/chat/api';
import { ContactsMockApi } from 'app/mock-api/apps/contacts/api';
import { ECommerceInventoryMockApi } from 'app/mock-api/apps/ecommerce/inventory/api';
import { FileManagerMockApi } from 'app/mock-api/apps/file-manager/api';
import { HelpCenterMockApi } from 'app/mock-api/apps/help-center/api';
import { MailboxMockApi } from 'app/mock-api/apps/mailbox/api';
import { NotesMockApi } from 'app/mock-api/apps/notes/api';
import { ScrumboardMockApi } from 'app/mock-api/apps/scrumboard/api';
import { TasksMockApi } from 'app/mock-api/apps/tasks/api';
import { AuthMockApi } from 'app/mock-api/common/auth/api';
import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import { AnalyticsMockApi } from 'app/mock-api/dashboards/analytics/api';
import { CryptoMockApi } from 'app/mock-api/dashboards/crypto/api';
import { FinanceMockApi } from 'app/mock-api/dashboards/finance/api';
import { ProjectMockApi } from 'app/mock-api/dashboards/project/api';
import { ActivitiesMockApi } from 'app/mock-api/pages/activities/api';
import { IconsMockApi } from 'app/mock-api/ui/icons/api';
import { SharedMockApi } from 'app/core/shared/_shared-api.mock';
import { ManageUserMockApi } from 'app/core/shared/mock-api/assistance/_manage-user.api';
import { CompanyFacilitiesMockApi } from 'app/core/shared/mock-api/data-management/_company-facilities.api';
import { CompanyContractsMockApi } from 'app/core/shared/mock-api/data-management/_company-contracts.api';
import { CompanySitesSubsitesMockApi } from 'app/core/shared/mock-api/data-management/_company-sites-subsites.api';
import { CompanyWellWellboreWellboreStringMockApi } from 'app/core/shared/mock-api/data-management/_company-well-wellbore-wellboreString.api';
import { CompanyEquipmentsMockApi } from 'app/core/shared/mock-api/data-management/_company-equipments.api';
import { MetadataServiceTypesMockApi } from 'app/core/shared/mock-api/configurations/_metadata-service-types.api';
import { MetadataEquipmentManufacturersMockApi } from 'app/core/shared/mock-api/configurations/_metadata-equipment-manufacturers.api';
import { MetadataFacilityTypesMockApi } from 'app/core/shared/mock-api/configurations/_metadata-facility-types.api';
import { MetadataPlatformTypesMockApi } from 'app/core/shared/mock-api/configurations/_metadata-platform-types.api';
import { MetadataOperationalMockApi } from 'app/core/shared/mock-api/configurations/_metadata-operational.api';
import { MetadataUtilizationCategoriesMockApi } from 'app/core/shared/mock-api/configurations/_metadata-utilization-categories.api';
import { MetadataLocationSublocationMockApi } from 'app/core/shared/mock-api/configurations/_metadata-location-sublocation.api';
import { MetadataUnitDimensionsMockApi } from 'app/core/shared/mock-api/configurations/_metadata-unit-dimensions.api';
import { MetadataWellTypesMockApi } from 'app/core/shared/mock-api/configurations/_metadata-well-types.api';
import { MetadataEquipmentCategoriesMockApi } from 'app/core/shared/mock-api/configurations/_metadata-equipment-categories.api';
import { MetadataPceMockApi } from 'app/core/shared/mock-api/configurations/_metadata-pce.api';
import { MetadataToolstringMockApi } from 'app/core/shared/mock-api/configurations/_metadata-toolstring.api';
import { UserRegistrationMockApi } from 'app/core/shared/mock-api/configurations/_user-registration.api';
import { UserRolePermissionMockApi } from 'app/core/shared/mock-api/configurations/_user-role-permission.api';
import { WsoProjectMockApi } from 'app/core/shared/mock-api/business-process/_wso-project.api';
import { ExtractionMockApi } from 'app/core/shared/mock-api/project/extraction.api';

export const mockApiServices = [
    AcademyMockApi,
    ActivitiesMockApi,
    AnalyticsMockApi,
    AuthMockApi,
    ChatMockApi,
    ContactsMockApi,
    CryptoMockApi,
    ECommerceInventoryMockApi,
    FileManagerMockApi,
    FinanceMockApi,
    HelpCenterMockApi,
    IconsMockApi,
    MailboxMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotesMockApi,
    NotificationsMockApi,
    ProjectMockApi,
    SearchMockApi,
    ScrumboardMockApi,
    ShortcutsMockApi,
    TasksMockApi,
    UserMockApi,
    SharedMockApi,
    ManageUserMockApi,
    CompanyFacilitiesMockApi,
    CompanyContractsMockApi,
    CompanySitesSubsitesMockApi,
    CompanyWellWellboreWellboreStringMockApi,
    CompanyEquipmentsMockApi,
    MetadataServiceTypesMockApi,
    MetadataEquipmentManufacturersMockApi,
    MetadataFacilityTypesMockApi,
    MetadataPlatformTypesMockApi,
    MetadataOperationalMockApi,
    MetadataUtilizationCategoriesMockApi,
    MetadataLocationSublocationMockApi,
    MetadataUnitDimensionsMockApi,
    MetadataWellTypesMockApi,
    MetadataEquipmentCategoriesMockApi,
    MetadataPceMockApi,
    MetadataToolstringMockApi,
    UserRegistrationMockApi,
    UserRolePermissionMockApi,
    WsoProjectMockApi,
    ExtractionMockApi
];
