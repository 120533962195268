import { CustomType } from "app/core/shared/shared.type";

export const facilityTypeData: CustomType[] = [
  {
    id: '1',
    label: 'Office',
    value: 1
  },
  {
    id: '2',
    label: 'Warehouse',
    value: 2
  },
  {
    id: '3',
    label: 'Oilfield Services Yard',
    value: 3
  },
  {
    id: '4',
    label: 'Refinery',
    value: 4
  },
  {
    id: '5',
    label: 'Terminal',
    value: 5
  },
  {
    id: '6',
    label: 'Drilling Support Base',
    value: 6
  },
  {
    id: '7',
    label: 'Exploration Office',
    value: 7
  },
  {
    id: '8',
    label: 'Laboratory',
    value: 8
  },
  {
    id: '9',
    label: 'Trading Office',
    value: 9
  },
  {
    id: '10',
    label: 'Processing Plant',
    value: 10
  }
]
