import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { CompanySiteForm, CompanySubsiteForm } from 'app/core/shared/types/company/company-site-subsite.type';

@Injectable({ providedIn: 'root' })
export class CompanySitesSubsitesMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/data-management/company/field')
      .reply(() => {
        // Generate a new field aka site
        const newField: CompanySiteForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: null,
          [Field.OperatorOrContractorId]: null,
          [Field.SiteName]: '',
          [Field.SiteType]: 1,
          [Field.Latitude]: 0,
          [Field.Longitude]: 0,
          [Field.RegionId]: null,
          [Field.SubRegionId]: null,
          [Field.BlockName]: null,
          [Field.DistrictName]: null,
          [Field.CityId]: null,
          [Field.StateId]: null,
          [Field.CountryId]: 135,
          [Field.Remark]: null,
          [Field.FacilityType]: null,
          [Field.IsCompanyOwner]: false
        };

        // Return the response
        return [200, newField];
      });

    this._fuseMockApiService
      .onPost('api/data-management/company/platform')
      .reply(() => {
        // Generate a new platform aka subsite
        const newPlatform: CompanySubsiteForm = {
          [Field.ID]: 'new',
          [Field.SiteId]: null,
          [Field.PlatformTypeId]: null,
          [Field.SubsiteName]: '',
          [Field.SubsiteType]: null,
          [Field.Latitude]: 0,
          [Field.Longitude]: 0,
          [Field.Remark]: null,
          [Field.Description]: null,
          [Field.FacilityType]: null
        };

        // Return the response
        return [200, newPlatform];
      });
  }
}
