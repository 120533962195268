import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap, forkJoin, of, take, tap } from 'rxjs';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { UserService } from 'app/core/user/user.service';

export const initialDataResolver = (route, state) => {
  const navigationService = inject(NavigationService);
  const notificationsService = inject(NotificationsService);
  const shortcutsService = inject(ShortcutsService);
  const router: Router = inject(Router);
  const userService = inject(UserService);

  // Call userService.getCurrentUser() first
  return userService.getCurrentUser().pipe(
    concatMap(() => {
      // Then fork join other API endpoint calls to wait all of them to finish
      return forkJoin([
        navigationService.get(),
        notificationsService.getAll(),
        shortcutsService.getAll(),
      ]).pipe(
        take(1),
        tap((result) => {
          const navigationItem: FuseNavigationItem = getAllChildItems(result[0].horizontal).find((childItem) => {
            const splittedString = state.url.split('/');
            const extractedString = splittedString.slice(0, 4).join('/');
            const splitCount = splittedString.filter((part: string) => extractedString.includes(part));
            const foundLink = splitCount.length >= 2 && extractedString.includes(childItem.link);

            if (foundLink) {
              return childItem;
            }
          });

          if (!navigationItem) {
            const urlPath = router.navigate(['/']);
            return of(urlPath);
          }

          return of(true);
        })
      );
    })
  );
};

export const getAllChildItems = (childItems: FuseNavigationItem[]): FuseNavigationItem[] => {
  const items: FuseNavigationItem[] = [];
 
  for (const childItem of childItems) {
    items.push(childItem); // Pushing the childItem object itself
 
    if (childItem.children) {
      const childItems = getAllChildItems(childItem.children);
      items.push(...childItems);
    }
  }
 
  return items;
}