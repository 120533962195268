import { CustomType } from "app/core/shared/shared.type";

export const subsiteTypeData: CustomType[] = [
  {
    id: '1',
    label: 'Platform',
    value: 1
  },
  {
    id: '2',
    label: 'WellPad',
    value: 2
  }
]
