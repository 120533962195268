import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { UserRegistrationForm } from 'app/core/shared/types/user.type';

@Injectable({ providedIn: 'root' })
export class UserRegistrationMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/configurations/users/registration')
      .reply(() => {
        // Generate a new user
        const newRegistration: UserRegistrationForm = {
          [Field.CompanyId]: null,
          [Field.UserId]: 'new',
          [Field.FirstName]: '',
          [Field.LastName]: '',
          [Field.Email]: null,
          [Field.DOB]: null,
          [Field.PhoneCode]: '+60',
          [Field.PhoneNumber]: null,
          [Field.ImageFile]: null,
          [Field.IsAgreed]: null,
          [Field.RoleIds]: [],
          [Field.PermissionIds]: []
        };

        // Return the response
        return [200, newRegistration];
      });
  }
}
