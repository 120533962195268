import { CustomType } from "app/core/shared/shared.type";

export const approvalCandidateData: CustomType[] = [
  {
    id: '1',
    label: 'Zulfakar Bayu',
    value: 'Zulfakar Bayu'
  },
  {
    id: '2',
    label: 'Roslan Bainal',
    value: 'Roslan Bainal'
  },
  {
    id: '3',
    label: 'Ammar Moin',
    value: 'Ammar Moin'
  },
  {
    id: '4',
    label: 'Redzuan Rahman',
    value: 'Redzuan Rahman'
  }
]
