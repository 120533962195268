export enum UserLayoutEnum {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
  UNKNOWN = 'Unknown'
}

export enum UserTypeEnum {
  INTERNAL = 'Internal',
  EXTERNAL = 'External',
  UNKNOWN = 'Unknown'
}

export enum UserRoleEnum {
  DEFAULT = 'Default',
  CS = 'Customer Service',
  ST = 'Support Team',
  BT = 'Billing Team',
  MT = 'Marketing Team',
  HR = 'HR Team'
}

export enum UserPermissionEnum {
  FULL = 'full',
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  REPORT = 'report',
  PRINT = 'print',
  APPROVAL = 'approval',
}