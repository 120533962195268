import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Field } from 'app/core/custom/field.enum';
import { CompanyContractForm } from 'app/core/shared/types/company/company-contract.type';

@Injectable({ providedIn: 'root' })
export class CompanyContractsMockApi {
  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Member - POST
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService
      .onPost('api/data-management/company/contract')
      .reply(() => {
        // Generate a new contract
        const newContract: CompanyContractForm = {
          [Field.ID]: 'new',
          [Field.CompanyId]: null,
          [Field.ServiceTypeId]: null,
          [Field.OperatorOrContractorId]: null,
          [Field.Division]: null,
          [Field.ContractName]: '',
          [Field.ContractParty]: null,
          [Field.ContractScope]: null,
          [Field.ContractOwner]: null,
          [Field.ContractEmail]: null,
          [Field.ContractPhone]: null,
          [Field.PhoneCode]: '+60',
          [Field.TermCondition]: null,
          [Field.Type]: null,
          [Field.DateRange]: null,
          [Field.StartDate]: null,
          [Field.EndDate]: null,
          [Field.Value]: 0,
          [Field.ConvertedValue]: 0,
          [Field.UnitValue]: 0,
          [Field.MainCurrency]: 'USD',
          [Field.SecondCurrency]: 'MYR',
          [Field.Remark]: null,
        };

        // Return the response
        return [200, newContract];
      });
  }
}
