import { NgModule } from '@angular/core';
import { StickyDirective } from '360kit/directives/sticky/sticky.directive';

@NgModule({
  declarations: [
    StickyDirective
  ],
  exports: [
    StickyDirective
  ]
})
export class StickyModule {
}
