import { Directive, ElementRef, Inject, OnDestroy, Renderer2 } from "@angular/core";
import { fromEvent, Observable, Subject } from "rxjs";
import {
  distinctUntilChanged,
  map,
  pairwise,
  startWith,
  takeUntil
} from "rxjs/operators";
import { WINDOW } from "@ng-web-apis/common";

const THRESHOLD = 200;

@Directive({
  selector: "[sticky]"
})
export class StickyDirective implements OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(WINDOW) windowRef: Window,
    private renderer: Renderer2, { nativeElement }: ElementRef<HTMLElement>
  ) {
    
    fromEvent(windowRef, "scroll")
      .pipe(
        map(() => windowRef.scrollY),
        pairwise(),
        map(([prev, next]) => next < THRESHOLD || prev > next),
        distinctUntilChanged(),
        startWith(true),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(stuck => {
        this.renderer.setAttribute(nativeElement, "data-stuck", String(stuck));
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
  }
}
