import { CustomType } from "app/core/shared/shared.type";

export const unitsData: CustomType[] = [
  {
    id: 'ft',
    label: 'feet',
    value: 'ft'
  },
  {
    id: 'm',
    label: 'meter',
    value: 'm'
  }
];

export const unitTypeData: CustomType[] = [
  {
    id: '1',
    label: 'Depth',
    value: 'depth'
  },
  {
    id: '2',
    label: 'ID/OD',
    value: 'id/od'
  }
]

export const unitConversionByTypeData: CustomType[] = [
  {
    id: 'depth',
    label: 'Meter',
    value: 'm'
  },
  {
    id: 'depth',
    label: 'Feet',
    value: 'ft'
  },
  {
    id: 'id/od',
    label: 'Inch',
    value: 'inch'
  },
  {
    id: 'id/od',
    label: 'Centimeter',
    value: 'cm'
  }
]

