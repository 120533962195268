import { CustomType } from "app/core/shared/shared.type";

export const personnelShiftData: CustomType[] = [
  {
    id: '1',
    label: 'Day',
    value: 'Day'
  },
  {
    id: '2',
    label: 'Night',
    value: 'Night'
  }
]
